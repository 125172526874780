<!--
 * @Descripttion: 户型管理
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-13 09:49:53
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-29 10:16:04
-->
<template>
    <div class="familyManagement">
        <div class="title-wrap">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'housingTypeManagement' }">小区列表</el-breadcrumb-item>
                <el-breadcrumb-item>小区户型<span>({{family.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="case-wrap">
            <div class="add-wrap">
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
                <el-button @click="clickShowScene" class="btns" type="primary">添加户型</el-button>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column>
                    <el-table-column align="center" prop="cover" label="户型图片" width="80">
                        <template slot-scope="scope">
                            <img :src="scope.row.cover" height="30" />
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="户型名称" ></el-table-column>
                    <el-table-column align="center" prop="framework" label="户型结构" ></el-table-column>
                    <el-table-column align="center" prop="design_case_count" label="全景案例数" ></el-table-column>
                    <el-table-column align="center" prop="create_timex" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" label="操作" width="90">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="isShowScene" 
            @close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title" v-if="editId<0">添加户型</div>
            <div class="title" v-else>编辑户型</div>
            <div class="content" v-loading="dialogLoading">
                <el-form :model="plotForm" :rules="plotRules" ref="plotForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="户型名称" prop="name">
                        <el-input v-model="plotForm.name" placeholder="请输入户型名称"></el-input>
                    </el-form-item>
                    <el-form-item label="户型结构" prop="framework">
                        <el-input v-model="plotForm.framework" placeholder="请输入户型结构"></el-input>
                    </el-form-item>
                    <el-form-item label="户型图片" prop="cover">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="image.length<1" listType="picture-card"
                                v-model="image">
                            </aliyun-upload>
                            <div v-if="image.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="image" :src="image[0]"/>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="btn-wrap">
                <el-button v-if="editId<0" :loading="btnLoading" @click="creationPaves" type="primary">添加</el-button>
                <el-button v-else :loading="btnLoading" @click="editPaves" type="primary">编辑</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    data () {
        return {
            loading: false,
            dialogLoading: false,
            tableData: [],
            total: 0,
            currentPage: 1,
            isShowScene: false,
            keyword: '',
            epOptions: [],
            shopOptions: [],
            srdOptions: [],
            plotForm: {
                building_id: '',
                name: '',
                cover: '',
                framework: ''
            },
            plotRules: {
                name: [
                    { required: true, message: '请输入户型名称', trigger: 'blur' },
                ],
                framework: [
                    { required: true, message: '请输入户型结构', trigger: 'blur' },
                ],
                cover: [
                    { required: true, message: '请上传图片', trigger: 'blur' },
                ],
            },
            image: [],
            btnLoading: false,
            editId: -1,
            family: {}
        }
    },
    watch: {
        image(newData, oldData) {
            this.plotForm.cover = newData[0]
        }
    },
    mounted () {
        this.family = JSON.parse(localStorage.getItem('family'));
        this.plotForm.building_id = this.family.id;
        this.getHouseTypeIndex();
        this.getHouseType();
    },
    methods: {
        // 获取小区数据
        getHouseType(){
            common.connect("/distributorpcv1/scene_case/buildingRoomConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.srdOptions = resultSecond;
                this.dialogLoading = false;
            });
        },
        // 创建
        creationPaves(){
            this.$refs.plotForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    common.connect('/distributorpcv1/scene_case/storeBuildingRoom',this.plotForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getHouseTypeIndex();
                            this.isShowScene = false;
                            this.image = [];
                            common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-创建小区户型'})
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑
        editPaves(){
            this.$refs.plotForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.plotForm.id = this.editId;
                    common.connect('/distributorpcv1/scene_case/updateBuildingRoom',this.plotForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getHouseTypeIndex();
                            this.isShowScene = false;
                            this.image = [];
                            this.editId = -1;
                            common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-创建小区户型'})
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect('/distributorpcv1/scene_case/editBuildingRoom',{id: row.id},(res)=>{
                this.isShowScene = true;
                this.image[0] = res.data.cover;
                this.plotForm.name = res.data.name;
                this.plotForm.framework = res.data.framework;
                this.plotForm.cover = res.data.cover;
            })
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该户型, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/scene_case/deleteBuildingRoom',{id: row.id},(res)=>{
                    this.getHouseTypeIndex();
                    common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC删除小区户型'})
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 获取户型列表数据
        getHouseTypeIndex(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                building_id: this.family.id
            }
            this.loading = true;
            common.connect('/distributorpcv1/scene_case/buildingRoomIndex',params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
        },
        // 搜索
        clickSearch(){
            this.getHouseTypeIndex();
        },
        delImageList(){
            this.image.splice(0, 1)
        },
        clickShowScene(){
            this.isShowScene = true;
        },
        // 关闭弹窗
        handleClose(){
            let plotForm = {
                name: '',
                framework: '',
                cover: '',
                building_id: this.family.id
            }
            this.isShowScene = false;
            this.editId = -1;
            this.image = [];
            this.plotForm = plotForm;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getHouseTypeIndex();
        }
    },
}
</script>

<style lang='scss'>
.familyManagement{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
    }
    .add-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
        }
    }
    .table-wrap{
        margin-top: 20px;
        .image{
            width: 40px;
            cursor: pointer;
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            .iconfont{
                font-size: 16px;
                padding: 0 10px;
                cursor: pointer;
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
    .dialog-wrap{
        width: 700px;
        .title{
            font-size: 16px;
            color: #000;
            margin-left: 20px;
        }
        .el-select{
            width: 100%;
        }
        .content{
            margin: 40px 20px 20px 20px;
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            padding: 15px 0;
        }
    }
    .el-breadcrumb__item{
        .el-breadcrumb__inner{
            color: #000;
            font-weight: bold;
        }
        .is-link{
            color: #333;
            font-weight: 300;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>

